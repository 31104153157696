import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Box from './Styles/Box';
import Typography from './Styles/Typography';

function LeaderCompactRight({
  className,
  heading,
  headingLevel,
  headingClass,
  textColor,
  backgroundColor,
  accentColor,
}) {
  return (
    <Styles className={className}>
      <TypographyStyles className={headingClass} as={headingLevel}>
        {heading}
      </TypographyStyles>
    </Styles>
  );
}

const Styles = styled(Box)`
  position: relative;
  background: var(--darkPurple);
  color: var(--light);
  padding: 2em 0 2em 10%;
  top: -4em;
  border-radius: 100px 0 0 100px;
  ::before {
    content: '';
    height: 100%;
    width: 50%;
    background: var(--darkPurple);
    position: absolute;
    right: -50%;
    top: 0;
  }
  @media (min-width: 960px) {
    margin-left: 25%;
    border-radius: 1000px 0 0 1000px;
  }
`;

const TypographyStyles = styled(Typography)`
  margin-bottom: 0;
`;

LeaderCompactRight.defaultProps = {
  heading: 'The quick brown fox.',
  headingLevel: 'h2',
};

export default LeaderCompactRight;
