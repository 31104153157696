import React from 'react';
import styled from 'styled-components';
import { graphql, Link } from 'gatsby';
import Layout from '../../components/Layout';
import SEO from '../../components/Seo';
import PageHeader from '../../components/PageHeader';
import Section from '../../components/Section';
import Grid from '../../components/Styles/Grid';
import Box from '../../components/Styles/Box';
import Heading from '../../components/Styles/Heading';
import Text from '../../components/Styles/Text';
import Typography from '../../components/Styles/Typography';
import CardBasic from '../../components/CardBasic';
import Inner from '../../components/Styles/Inner';
import List from '../../components/List';
import ListItem from '../../components/ListItem';
import SingleLineTextWithIcon from '../../components/SingleLineTextWithIcon';
import Chip from '../../components/Styles/Chip';
import LeaderCompactLeft from '../../components/LeaderCompactLeft';
import LeaderCompactRight from '../../components/LeaderCompactRight';
import BigNumber from '../../components/BigNumber';

import CheckIcon from '../../components/Icons/Fa/Thin/CheckIcon';

const Services = (props) => {
  const { data } = props;
  return (
    <Layout>
      <SEO title="Web design &amp; development Services in Natick, MA" />
      <PageHeaderStyled
        heading="Web design &amp; development Services in Natick, MA"
        subHeading=""
        buttonText="Request a Free Quote"
        buttonLink="/contact/"
      />

      <Section>
        <LeaderCompactLeft
          heading="Web Design"
          headingLevel="span"
          headingClass="h2"
        />
        {/* <Box>
          <Typography as="h2">Good design is clear thinking</Typography>
          <h3>
            Our designs are smart, honest and thoughtful because we believe in
            the power of authentic human connection.
          </h3>
          <Text>
            Whether you are considering a website redesign, mobile site, or
            brand refresh, we focus on your users. How will they interact with
            the design? What is the website asking them to do? Using design as a
            strategic tool, we help business owners and managers envision and
            create memorable brand experiences that engage and influence
            positive user behavior.
          </Text>
        </Box> */}
        <Grid
          gridTemplateColumns={['1fr', '1fr 1fr', '1fr 1fr 1fr 1fr']}
          gridGap={[1, 2, 2]}
        >
          <Box>
            <BigNumber text="01" />
            <Typography as="h3">Websites</Typography>
            <List>
              <ListItem icon={<CheckIcon />} text="Responsive Design" />
              <ListItem icon={<CheckIcon />} text="Single Page Websites" />
              <ListItem icon={<CheckIcon />} text="Landing Pages" />
              <ListItem icon={<CheckIcon />} text="WordPress Theme Design" />
              <ListItem icon={<CheckIcon />} text="Prototyping" />
            </List>
          </Box>
          <Box>
            <BigNumber text="02" />
            <Typography as="h3">User Experience (UX)</Typography>
            <List>
              <ListItem icon={<CheckIcon />} text="Information Architecture" />
              <ListItem icon={<CheckIcon />} text="Accessibility" />
              <ListItem icon={<CheckIcon />} text="Design Style Guides" />
              <ListItem icon={<CheckIcon />} text="Mockups" />
              <ListItem
                icon={<CheckIcon />}
                text="User Interface (UI) Design"
              />
            </List>
          </Box>
        </Grid>
      </Section>

      <SectionLightGray topDivider={true} bottomDivider={true}>
        <LeaderCompactRight
          heading="Development"
          headingLevel="span"
          headingClass="h2"
        />
        {/* <Box>
          <Heading>Best Development in the world</Heading>
          <Text>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Provident
            illo nam, optio temporibus ipsam tempora molestias quaerat quisquam,
            facere quidem vitae exercitationem perferendis nisi doloremque quam
            earum sunt atque laborum! Aliquam facilis, maxime saepe labore atque
            beatae nemo voluptatem modi sint porro libero quae ab natus pariatur
            minus accusamus quo voluptas tenetur sequi perspiciatis aspernatur
            enim. Sit, numquam ullam? Recusandae officiis exercitationem laborum
            repellendus necessitatibus nam quibusdam rem temporibus ex, aliquid
            ad quia dolorum accusamus!
          </Text>
          <Link to="#">Learn More</Link>
        </Box> */}
        <Grid
          gridTemplateColumns={['1fr', '1fr 1fr', '1fr 1fr 1fr 1fr']}
          gridGap={[1, 2, 2]}
        >
          <Box>
            <BigNumber text="01" />
            <Typography as="h3">Website Development</Typography>
            <List>
              <ListItem icon={<CheckIcon />} text="Responsive Design" />
              <ListItem icon={<CheckIcon />} text="WordPress Design" />
              <ListItem icon={<CheckIcon />} text="Prototyping" />
              <ListItem icon={<CheckIcon />} text="Landing Pages" />
            </List>
          </Box>
          <Box>
            <BigNumber text="02" />
            <Typography as="h3">Website Design</Typography>
            <List>
              <ListItem
                icon={<CheckIcon />}
                text="Custom Responsive Websites"
              />
              <ListItem icon={<CheckIcon />} text="Wordpress Development" />
              <ListItem icon={<CheckIcon />} text="React Development" />
              <ListItem icon={<CheckIcon />} text="Landing Pages" />
            </List>
          </Box>
          <Box>
            <BigNumber text="03" />
            <Typography as="h3">WordPress Development</Typography>
            <List>
              <ListItem icon={<CheckIcon />} text="Custom Wordpress Themes" />
              <ListItem icon={<CheckIcon />} text="Woo-Commerce Development" />
              <ListItem icon={<CheckIcon />} text="Content Migration" />
              <ListItem icon={<CheckIcon />} text="Custom Plugins" />
            </List>
          </Box>
          <Box>
            <BigNumber text="04" />
            <Typography as="h3">E-Commerce</Typography>
            <List>
              <ListItem icon={<CheckIcon />} text="Stripe Integration" />
              <ListItem icon={<CheckIcon />} text="PayPal Integration" />
              <ListItem icon={<CheckIcon />} text="Shopify Development" />
              <ListItem icon={<CheckIcon />} text="Cart Integrations" />
              <ListItem icon={<CheckIcon />} text="Custom Storefronts" />
            </List>
          </Box>
        </Grid>
      </SectionLightGray>

      <Section bottomDivider={true}>
        <LeaderCompactLeft
          heading="Support &amp; Maintenance"
          headingLevel="span"
          headingClass="h2"
        />
        {/* <Box>
          <Heading>
            Support &amp; Maintenance that will blow you awayyyyy!
          </Heading>
          <Text>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Provident
            illo nam, optio temporibus ipsam tempora molestias quaerat quisquam,
            facere quidem vitae exercitationem perferendis nisi doloremque quam
            earum sunt atque laborum! Aliquam facilis, maxime saepe labore atque
            beatae nemo voluptatem modi sint porro libero quae ab natus pariatur
            minus accusamus quo voluptas tenetur sequi perspiciatis aspernatur
            enim. Sit, numquam ullam? Recusandae officiis exercitationem laborum
            repellendus necessitatibus nam quibusdam rem temporibus ex, aliquid
            ad quia dolorum accusamus!
          </Text>
          <Link to="#">Learn More</Link>
        </Box> */}
        <Grid
          gridTemplateColumns={['1fr', '1fr 1fr', '1fr 1fr 1fr 1fr']}
          gridGap={[1, 2, 2]}
        >
          <Box>
            <BigNumber text="01" />
            <Typography as="h3">Maintenance</Typography>
            <List>
              <ListItem icon={<CheckIcon />} text="Website Maintenance Plans" />
              <ListItem icon={<CheckIcon />} text="Wordpress Maintenance" />
              <ListItem icon={<CheckIcon />} text="Hacked Website Repair" />
            </List>
          </Box>
          <Box>
            <BigNumber text="02" />
            <Typography as="h3">Performance</Typography>
            <List>
              <ListItem icon={<CheckIcon />} text="Load-time Improvements" />
              <ListItem icon={<CheckIcon />} text="Website Audits" />
            </List>
          </Box>
          <Box>
            <BigNumber text="03" />
            <Typography as="h3">Content Management</Typography>
            <List>
              <ListItem icon={<CheckIcon />} text="Content Migration" />
              <ListItem icon={<CheckIcon />} text="Content Updates" />
              <ListItem icon={<CheckIcon />} text="Content Additions" />
            </List>
          </Box>
          <Box>
            <BigNumber text="04" />
            <Typography as="h3">Hosting</Typography>
            <List>
              <ListItem icon={<CheckIcon />} text="Website Migrations" />
              <ListItem icon={<CheckIcon />} text="Managed Web Hosting" />
            </List>
          </Box>
        </Grid>
      </Section>
    </Layout>
  );
};

export default Services;

const PageHeaderStyled = styled(PageHeader)`
  min-height: 35vh;
`;

const SectionLightGray = styled(Section)`
  background: var(--lightBlue);
`;

export const query = graphql`
  query {
    rocketDeveloper: file(relativePath: { eq: "rocket-developer.png" }) {
      childImageSharp {
        fluid(maxWidth: 750) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    contentMarketing: file(
      relativePath: { eq: "content-marketing_202307290.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 650) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    gatsbyIcon: file(relativePath: { eq: "gatsby-icon.png" }) {
      childImageSharp {
        fluid(maxWidth: 650) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
  }
`;
